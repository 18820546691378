import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { AiFillCaretRight } from 'react-icons/ai';
import ItemService from '../../components/item-service/item.service';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TopBar from '../../components/top-bar/top-bar';
import {
  Button, Container, Section, Wrapper, Image, HighLight,
} from '../../styles/servicios/redes-electricas.elements';

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Redes electricas"}) {
        title
        images {
          gatsbyImageData
        }
      }
    }
  `);
  const { images } = data.contentfulPages;
  return (
    <Layout>
      <main>
        <TopBar title="SERVICIO DE REDES ELÉCTRICAS" imageName="breaker-57.jpeg" next="seguridad" previous="cableado-estructurado" />
        <Section>
          <Wrapper>
            <Container>
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="Inspeccion tablero electrico" objectFit="contain" />
              <Container className="mid">
                <HighLight>
                  Diseñamos sistemas eléctricos eficientes, seguros y a la medida. Ofrecemos alternativas innovadoras con soluciones viables de costo-beneficio, cumpliendo con la normatividad vigente
                </HighLight>
                <HighLight>
                  Brindamos servicio de mantenimiento predictivo, preventivo y correctivo en media y baja tensión
                </HighLight>
                <HighLight>
                  <strong><u>CUMPLIMIENTO DE NORMA RETIE Y NTC</u></strong> <br /><br />

                  Contamos con personal calificado para tareas que involucren riesgo eléctrico, nuestro personal cuenta con tarjeta CONTÉ y la empresa con el SGSST.
                </HighLight>
                <Button to="/contactenos">Contactenos <AiFillCaretRight className="plus" /></Button>
              </Container>
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container className="gap">
              <ItemService title="Diseño, consultaría y ejecución en redes eléctricas" description="Diseño, consultaría, interventoría y ejecución en redes eléctricas de baja y media tensión. Electrica normal y regulada, diseño para oficinas, bodegas, sector industrial, etc.." image={getImage(images[1]) as IGatsbyImageData} altImage="Planos electricos" />

              <ItemService title="Diseño e instalación de sistemas de iluminación" description="Sistemas de iluminación en cumplimiento de la norma RETILAP, Iluminación led tipo industrial, interior y exterior e iluminacion autosustentable por energia solar." image={getImage(images[2]) as IGatsbyImageData} altImage="Iluminacion en oficinas" />

              <ItemService title="Diseño y fabricación de tableros eléctricos" description="Diseño, fabricación y conexión de tableros eléctricos de potencia y tableros eléctricos de control con certificado RETIE. Tableros para bombas, aires y maquinaria eléctrica en general." image={getImage(images[3]) as IGatsbyImageData} altImage="Diseño tablero electrico con bypass" />

              <ItemService title="UPS: Suministro, mantenimiento e instalación" description="Suministro, traslado, instalacion, matenimiento preventivo y correctivo de UPS de diversas marcas como APC, Schneider, CDP, Tripplite, Vertiv. Transferencias para UPS online." image={getImage(images[4]) as IGatsbyImageData} altImage="Diferentes UPS" />

              <ItemService title="Termografías y análisis de red" description="Conozca el estado de sus sistemas de distrubucion de energia y de la red en general con el fin de detectar y resolver fallos antes de que se presenten y puedan causar la interrupcion de la red." image={getImage(images[5]) as IGatsbyImageData} altImage="Termografia de tablero electrico" />

              <ItemService title="Componentes redes eléctricas" description="Suministro e instalación de componentes asociados a las redes eléctricas,  cable eléctrico, protecciones eléctricas, totalizadores, tableros eléctricos, UPS, reguladores. PDU, interruptores, tomas y luminarias." image={getImage(images[6]) as IGatsbyImageData} altImage="Carretas de cable electrico" />
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default ServicePage;

export const Head: HeadFC = () => <Seo title="Redes Electricas" />;
